<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="$permission.isGmoOrOffice() ? pageNavs : []" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row" v-if="hasCustomersRole">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>お名前</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength="41"
                                  name="name"
                                  v-model="searchForm.name"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>フリガナ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="nameKana"
                                  v-model="searchForm.nameKana"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col" v-if="hasCustomersRole">
                        <FormRow>
                          <template v-slot:label>メールアドレス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength="255"
                                  v-email
                                  name="email"
                                  v-model="searchForm.email"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="hasCustomersRole">
                        <FormRow>
                          <template v-slot:label>電話番号</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="tel"
                                  v-trim
                                  v-number
                                  name="telephone"
                                  v-model="searchForm.telephone"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>user ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  name="userId"
                                  v-model="searchForm.userId"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>取引ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength="36"
                                  name="chargeId"
                                  v-model="searchForm.chargeId"
                                  @blur="handleBlur($event, 'chargeId')"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="!isPaymentType && (!isClientChild || viewAllhistory)">
                    <div class="row">
                      <div class="col" v-if="isMethodListShown">
                        <FormRow>
                          <template v-slot:label>購入方法</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="method" v-model="searchForm.method">
                                  <option value="">選択してください</option>
                                  <option v-for="item in methodList" :key="item.id" :value="item.id.toString()">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="!isPaymentTicketType">
                        <FormRow>
                          <template v-slot:label>GMO-PG オーダーID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-alphaNumSpecialHalfsize
                                  name="chargeOrderId"
                                  v-model="searchForm.chargeOrderId"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="isSearchFormOpened && (isManualShown || useTicketFlag)">
                    <div class="row">
                      <div class="col" v-if="isManualShown">
                        <FormRow>
                          <template v-slot:label>手動発行元</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="clientId" v-model="searchForm.clientId">
                                  <option value="">選択してください</option>
                                  <option v-for="item in companyList" :key="item.id" :value="item.id.toString()">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="useTicketFlag">
                        <FormRow>
                          <template v-slot:label>TICKET名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="ticketId" v-model="searchForm.ticketId">
                                  <option value="">選択してください</option>
                                  <option v-for="item in ticketList" :key="item.id" :value="item.id.toString()">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="isSearchFormOpened">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>期間指定</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <template>
                                <ul class="listGrid ml-10">
                                  <li class="listGrid-item mr-10">
                                    <label class="form-radio">すべて</label>
                                  </li>
                                  <li v-for="(item, index) in optionSpecifiedPeriod.all" :key="index" class="listGrid-item">
                                    <label class="form-radio">
                                      <input class="form-radio-input" type="radio" :value="item.value" v-model="searchForm.type" />
                                      <span class="form-radio-label">{{ item.label }}</span>
                                    </label>
                                  </li>
                                </ul>
                                <ul class="listGrid ml-10  mt--2">
                                  <li class="listGrid-item mr-10">
                                    <label class="form-radio">検索条件</label>
                                  </li>
                                  <li v-for="(item, index) in optionSpecifiedPeriod.condition" :key="index" class="listGrid-item">
                                    <label class="form-radio">
                                      <input class="form-radio-input" type="radio" :value="item.value" v-model="searchForm.type" />
                                      <span class="form-radio-label">{{ item.label }}</span>
                                    </label>
                                  </li>
                                </ul>
                              </template>
                              <div class="form-group mt-2">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="startDate"
                                      v-model="searchForm.startDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="endDate"
                                      v-model="searchForm.endDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button class="btn btn-white" type="button" @click="addSearch">{{ isSearchFormOpened ? '閉じる' : '高度な検索' }}</button>
                  </li>
                  <li class="listGrid-item pos-end">
                    <ul class="listGrid">
                      <li class="listGrid-item">
                        <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                      </li>
                      <li class="listGrid-item">
                        <button class="btn btn-main" type="button" @click="search">検索</button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>{{ title }}</template>
                  <template v-slot:subTitle>※取消分含まず</template>
                  <template v-slot:num>{{ totalAdded | comma }}</template>
                  <template v-slot:sup>円</template>
                  <template v-slot:sub v-if="isBalanceShown"><span class="numDisplay-sub-sup">購入金額合計</span>{{ balance | comma }}円</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox :storeModule="storeModule" :dataTable="dataTable" :labels="labels" />
                </div>
                <Modal @close="closeModal" v-if="modal">
                  <template v-slot:headline>
                    購入履歴詳細
                  </template>
                  <template v-slot:body>
                    <section class="section">
                      <p class="title">取引情報</p>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>取引ID</th>
                            <td colspan="2">{{ historyDetail.id }}</td>
                          </tr>
                          <tr>
                            <th>{{ getChargeDateLabel }}</th>
                            <td colspan="2">{{ getChargeDate | fullDateTime }}</td>
                          </tr>
                          <tr>
                            <th>更新日時</th>
                            <td colspan="2">{{ getChangeStatusDate | fullDateTime }}</td>
                          </tr>
                          <tr>
                            <th>購入金額</th>
                            <td v-if="isCancelType" colspan="2">{{ cancelLabel }}</td>
                            <td v-else-if="isAmountType" colspan="2">{{ chargeType.manual.label }}</td>
                            <td v-else colspan="2">¥{{ historyDetail.amount | comma }}</td>
                          </tr>
                          <tr v-if="!useTicketFlag">
                            <th :rowspan="historyDetail.addedBreakdown ? 4 : ''">チャージ金額</th>
                            <td colspan="2">¥{{ historyDetail.added | comma }}</td>
                          </tr>
                          <tr v-if="historyDetail.addedBreakdown">
                            <th colspan="2">使用できる金額</th>
                          </tr>
                          <tr v-for="item in historyDetail.addedBreakdown" :key="item.id">
                            <th>{{ item.name }}</th>
                            <td>¥{{ item.amount | comma }} まで</td>
                          </tr>
                          <tr class="table-child" v-if="useTicketFlag">
                            <th colspan="2">TICKET金額</th>
                            <td>
                              <span class="table-child-added">¥{{ historyDetail.added | comma }}</span>
                              <table>
                                <caption class="table-child-title">
                                  内訳
                                </caption>
                                <template>
                                  <tr v-for="(ticket, index) in historyDetail.tickets" :key="index">
                                    <th class='pre-line w-190'>{{ endline(ticket.name, 10) }}</th>
                                    <td>¥{{ ticket.amountBreakdown | comma }} x {{ (ticket.unitBreakdown * ticket.quantity) | comma }}枚</td>
                                  </tr>
                                </template>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <th>支払い方法</th>
                            <td v-if="chargedSource" colspan="2">{{ chargedSource }}</td>
                            <td v-else colspan="2">
                              {{ historyDetail.paymentType.name }}
                              <template v-if="isShowStatus">（{{ historyDetail.status }}）</template>
                            </td>
                          </tr>
                          <tr v-if="historyDetail.amountPoint && isAmountPointShown">
                            <th>{{ historyDetail.hasPlannedPoint ? '購入付与予定ポイント' : '購入付与ポイント' }}</th>
                            <td colspan="2">{{ historyDetail.amountPoint | comma }}P</td>
                          </tr>
                           <tr v-if="!!historyDetail.expirationDate">
                            <th>有効期限</th>
                            <td colspan="2">{{ historyDetail.expirationDate | fullDateTime }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                    <section class="section">
                      <p class="title">購入者情報</p>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>user ID</th>
                            <td colspan="2">{{ historyDetail.userId }}</td>
                          </tr>
                          <tr v-if="hasCustomersRole">
                            <th>お名前</th>
                            <td colspan="2" v-if="historyDetail.customerDeleteDate">退会ユーザー</td>
                            <td colspan="2" v-else>{{ historyDetail.name }}</td>
                          </tr>
                          <tr>
                            <th rowspan="4">購入後残高</th>
                            <td colspan="2">
                              ¥{{ historyDetail.charged | comma }}
                              <template v-if="isWaitingStatus || isConfirmStatus">（¥{{ historyDetail.added | comma }}）</template>
                            </td>
                          </tr>
                          <tr>
                            <th colspan="2" v-if="historyDetail.chargedBreakdown">使用できる金額</th>
                          </tr>
                          <tr v-for="(item, index) in historyDetail.chargedBreakdown" :key="item.id">
                            <th>{{ item.name }}</th>
                            <td>
                              ¥{{ item.amount | comma }} まで
                              <template v-if="isWaitingStatus">（¥{{ historyDetail.addedBreakdown[index].amount | comma }}）</template>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                    <section class="section" v-if="isOwnType">
                      <p class="title">GMO-PG情報</p>
                      <table class="table">
                        <tbody>
                          <tr v-if="isCreditCardChargeType">
                            <th>会員ID</th>
                            <td colspan="2">{{ historyDetail.memberId }}</td>
                          </tr>
                          <tr>
                            <th>オーダーID</th>
                            <td colspan="2">{{ historyDetail.chargeOrderId }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </section>
                  </template>
                  <template v-slot:footer>
                    <ul class="listGrid justify-content-end">
                      <li class="listGrid-item">
                        <button class="btn btn-white" type="button" @click="closeModal">閉じる</button>
                      </li>
                      <li class="listGrid-item">
                        <button class="btn btn-red"
                          type="button"
                          :disabled="isCancelDisabled"
                          @click="showCancelChargePopup"
                        >
                          キャンセルし払い戻す
                        </button>
                      </li>
                    </ul>
                  </template>
                </Modal>
                <Modal @close="closeModal('cancelPaymentModal')" v-if="cancelPaymentModal">
                <template v-slot:headline>キャンセルし払い戻す</template>
                  <template v-slot:body>
                    <p class="description text-align-center">
                      この購入は支払いが行われているため<br />
                      キャンセルとし払い戻し処理を行うことができません。
                    </p>
                    <ul class="listGrid justify-content-center">
                      <li class="listGrid-item">
                        <button
                          class="btn btn-lg btn-white"
                          type="button"
                          @click="closeModal('cancelPaymentModal')"
                        >
                          閉じる
                        </button>
                      </li>
                      <li class="listGrid-item">
                        <ActionButton
                          class="btn btn-lg btn-main"
                          :handle-submit="cancelPayment"
                          :disabled="isCancelDisabled"
                          button-text="支払履歴へ"
                        />
                      </li>
                    </ul>
                  </template>
                </Modal>
                <Modal @close="closeModal('cancelChargeModal')" v-if="cancelChargeModal">
                <template v-slot:headline>キャンセルし払い戻す</template>
                  <template v-slot:body>
                    <p class="description text-align-center">
                      この購入をキャンセルとし払い戻し処理を行いますか？<br />
                      この操作は取り消すことができません。
                    </p>
                    <ul class="listGrid justify-content-center">
                      <li class="listGrid-item">
                        <button
                          class="btn btn-lg btn-white"
                          type="button"
                          @click="closeModal('cancelChargeModal')"
                        >
                          いいえ
                        </button>
                      </li>
                      <li class="listGrid-item">
                        <ActionButton
                          class="btn btn-lg btn-red"
                          :handle-submit="cancelCharge"
                          :disabled="isCancelDisabled"
                          button-text="はい"
                        />
                      </li>
                    </ul>
                  </template>
                </Modal>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :listLength="historyCount"
                  :searchConditions="searchConditions"
                  :modulePath="modulePath"
                  :noGetPaginationList="noGetPaginationList"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ActionButton
              :disabled="!historyList.length || isDisable"
              class="btn btn-black"
              :handle-submit="hanldeClickDownload"
              button-text="CSVダウンロード"
            />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <ModalConfirmDownload v-if="downloadCSVModal" @cancelDownloadCsv="cancelDownloadCsv" @acceptDownloadCsv="acceptDownloadCsv" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
import nav from '@/mixins/nav/history';
import sort from '@/mixins/plugin/sort';
import modal from '@/mixins/plugin/modal';
import flatpickr from '@/mixins/plugin/flatpickr';
import search from '@/mixins/plugin/search';
import download from '@/mixins/plugin/download';
import company from '@/mixins/module/company';
import history from '@/mixins/module/history';
import { formatDateAndTime, formatLocalString, endline } from '@/helpers/formatData';
//contants
import { StatusConstants } from '@/constants/status';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import Modal from '@/components/Modal.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import ModalConfirmDownload from '@/components/ModalConfirmDownload.vue';

export default {
  name: 'EventHistoryBuy',
  data: function() {
    return {
      pageName: '購入履歴',
      selectedItem: {},
      normalModulePath: 'history/getHistoryCharges',
      ticketModulePath: 'history/getHistoryChargesTicket',
      normalCSVPath: 'history/getHistoryChargeCSV',
      ticketCSVPath: 'history/getHistoryChargeTicketCSV',
      searchConditions: {},
      storeModule: 'history',
      searchFields: ['name', 'email', 'chargeId', 'userId', 'clientId', 'type', 'startDate', 'endDate', 'method', 'ticketId', 'nameKana', 'chargeOrderId', 'telephone'],
      chargeType: StatusConstants.history.charge,
      storedField: 'idRequestExportBuyHistory',
      initialSearchConditions: {
        type: 1,
      },
      endline,
      isSearchFormOpened: false,
      cancelPaymentModal: false,
      cancelChargeModal: false,
    };
  },
  components: {
    FormRow,
    NumDisplay,
    Flatpickr,
    Modal,
    ModalConfirmDownload,
    PaginationLog,
    TableWithCheckbox,
  },
  mixins: [nav, flatpickr, modal, sort, search, download, company, history],
  computed: {
    ...mapGetters({
      historyList: 'history/historyCharges',
      historyCount: 'history/historyChargesCount',
      historyDetail: 'history/historyChargeDetail',
      childEventAggregate: 'aggregate/childEventAggregate',
      childEventDetail: 'event/childEventDetail',
      useTicketFlag: 'event/useTicketFlag',
      companyList: 'client/clientList',
      hasCustomersRole: 'auth/hasCustomersRole',
      isClientChild: 'auth/isClientChild',
      hasChargeManual: 'event/hasChargeManual',
      ticketList: 'history/ticketList',
      methodList: 'history/methodList',
      hasManualCharge: 'event/hasManualCharge',
      viewAllhistory: 'event/viewAllhistory',
      totalAdded: 'history/totalAdded',
    }),
    chargeAggregate() {
      return this.childEventAggregate.charges || {};
    },
    balance() {
      return this.childEventAggregate.charges?.amount;
    },
    subdomain() {
      return this.$route.params.subdomain;
    },
    labels() {
      const labelArr = [
        { key: 'id', name: '取引ID', tdClass: 'pre-line mw-130' },
        { key: 'userId', name: 'user ID' },
        { key: 'name', name: 'お名前', notShow: !this.hasCustomersRole, tdClass: 'pre-line' },
        { key: 'amount', name: '購入金額', keyMultiple: 'ticketName' },
        { key: 'added', name: this.useTicketFlag ? 'TICKET金額' : 'チャージ金額', keyMultiple: 'ticketName' },
        { key: 'ticketName', name: 'TICKET名', tdClass: 'dataTable-break mw-120', keyMultiple: 'ticketName', notShow: !this.useTicketFlag },
        { key: 'createDate', name: '購入日時', sortKey: 'createDate' },
        { key: 'changeStatusDate', name: '更新日時', sortKey: 'changeStatusDate' },
        { isButtonColumn: true },
      ];
      return labelArr;
    },
    dataTable() {
      return (
        this.historyList &&
        this.historyList.map((item) => {
          return {
            id: endline(item.id, 18),
            userId: item.userId,
            name: {
              content: item?.customerDeleteDate ? '退会ユーザー' : endline(item.name, 20),
              routeName: this.isClientChild ? 'EventUserDetail' : 'EventUserEdit',
              params: { customerId: item.customerId },
              isLink: !item.customerDeleteDate,
            },
            amount: this.getAmount(item),
            added: this.useTicketFlag ? (this.extractAmountFromArray(item, 'amountBreakdown')) : '¥' + formatLocalString(item.added),
            createDate: formatDateAndTime(item.createDate),
            changeStatusDate: formatDateAndTime(item.changeStatusDate),
            buttonLinks: [
              {
                content: '購入履歴詳細',
                class: 'btn btn-sm btn-bd-main',
                function: () => this.showModal('', item, 'history/getHistoryChargeDetail'),
              },
            ],
            ...(this.useTicketFlag ? { ticketName: this.extractTicketNameFromArray(item.ticketName) } : {})
          };
        })
      );
    },
    cancelLabel() {
      return `${this.chargeType.manual.label}(${StatusConstants.chargeStatus.cancel})`;
    },
    isAmountType() {
      return this.checkSpecialType(this.historyDetail);
    },
    isCancelType() {
      return (this.historyDetail?.type !== this.chargeType.own.value) && this.historyDetail?.status === StatusConstants.chargeStatus.cancel;
    },
    isCreditCardChargeType() {
      return this.historyDetail?.paymentType.id === StatusConstants.chargeType.creditcard.value;
    },
    isConvenienceStoreChargeType() {
      return this.historyDetail.paymentType.id === StatusConstants.chargeType.convenienceStore.value;
    },
    isPayeasyChargeType() {
      return this.historyDetail.paymentType.id === StatusConstants.chargeType.payeasy.value;
    },
    isOwnType() {
      return this.historyDetail?.type === this.chargeType.own.value;
    },
    isShowStatus() {
      return this.isConvenienceStoreChargeType || this.isPayeasyChargeType || this.checkCancelOwnType(this.historyDetail) || this.isConfirmStatus || this.isFailStatus;
    },
    chargedSource() {
      if (this.checkManualType(this.historyDetail)) {
        return this.historyDetail.shop?.name || '事務局';
      } else if (this.checkShopType(this.historyDetail)) {
        return this.historyDetail?.shop ? `店舗発行 (${this.historyDetail.shop?.name})` : `店舗発行`;
      } else if (this.checkIssuerType(this.historyDetail)) {
        return `企業発行 (${this.historyDetail.client?.name})`;
      }
      return '';
    },
    isWaitingStatus() {
      return this.historyDetail.status === StatusConstants.chargeStatus.waiting;
    },
    isConfirmStatus() {
      return this.historyDetail.status === StatusConstants.chargeStatus.confirm;
    },
    isFailStatus() {
      return this.historyDetail.status === StatusConstants.chargeStatus.fail;
    },
    hasGroup() {
      return !!this.childEventDetail?.groups?.length;
    },
    isPaymentType() {
      return this.childEventDetail?.type === StatusConstants.currency.parent.payment.cancel.value;
    },
    isPaymentTicketType(){
      return this.childEventDetail?.type === StatusConstants.chargeDateType.ticketPayment.value;
    },
    getChargeDateLabel() {
      return StatusConstants.chargeDateType.buy.label + '時';
    },
    getChargeDate() {
      return this.historyDetail.createDate;
    },
    getChangeStatusDate() {
      return this.historyDetail?.changeStatusDate;
    },
    optionSpecifiedPeriod() {
      return {
        all: [
          {
            value: 1,
            label: "購入日",
          },
          {
            value: 2,
            label: "更新日",
          },
        ],
        condition: [
          ...this.isPaymentTicketType || this.isPaymentType ?
          [] : [
            {
              value: 3,
              label: "入金済",
            },
            {
              value: 5,
              label: "入金待ち",
            },
            {
              value: 6,
              label: "認証待ち",
            },
            {
              value: 8,
              label: "認証失敗",
            },
          ],
          {
            value: 4,
            label: "キャンセル",
          },
          ...this.hasChargeManual ? [{ value: 7, label: "手動発行" }] : [],
        ]
      }
    },
    title() {
      return this.useTicketFlag ? 'TICKET金額合計' : 'チャージ金額合計';
    },
    isMethodListShown() {
      return this.methodList?.length >= 2 && (!this.isClientChild || !this.useTicketFlag);
    },
    isManualShown() {
      return !this.hasGroup && this.hasManualCharge;
    },
    isCancelDisabled() {
      return !this.historyDetail?.beAbleCancel;
    },
    isAmountPointShown() {
      return ![StatusConstants.chargeStatus.waiting, StatusConstants.chargeStatus.cancel, StatusConstants.chargeStatus.fail].includes(this.historyDetail?.status);
    },
    isBalanceShown() {
      return !this.isPaymentTicketType && !this.isPaymentType && (!this.isClientChild || this.viewAllhistory);
    }
  },
  methods: {
    extractAmountFromArray(data, key) {
      return this.customExtractAmountFromArray(data, true, key);
    },
    extractTicketNameFromArray(data) {
      return this.customExtractTicketNameFromArray(data, true);
    },
    async hanldeClickDownload() {
      this.isDisable = true;
      const result = await this.$store.dispatch(this.downloadCSVPath, {
        ...this.payloadDownloadCsv,
        subdomain: this.subdomain,
      });
      if (result) {
        this.saveIdRequestExport(result.id);
        this.checkExportCsvStatus(result.id);
      }
    },
    checkManualType(item) {
      return item.type === this.chargeType.manual.value;
    },
    checkShopType(item) {
      return item.type === this.chargeType.shop.value;
    },
    checkIssuerType(item) {
      return item.type === this.chargeType.issuer.value;
    },
    checkCancelOwnType(item, status = StatusConstants.chargeStatus.cancel) {
      return item.type === this.chargeType.own.value && item.status === status;
    },
    checkFailType(item) {
      return item.status === StatusConstants.chargeStatus.fail;
    },
    checkSpecialType(item) {
      return item.type !== this.chargeType.own.value && item.status === StatusConstants.chargeStatus.done;
    },
    getAmount(item) {
      if (this.checkSpecialType(item)) {
        return this.chargeType.manual.label;
      } else if (item.status === StatusConstants.chargeStatus.cancel) {
        return StatusConstants.chargeStatus.cancel;
      } else if (this.checkCancelOwnType(item, StatusConstants.chargeStatus.waiting)) {
        return StatusConstants.chargeStatus.waiting;
      } else if (this.checkCancelOwnType(item, StatusConstants.chargeStatus.confirm)) {
        return StatusConstants.chargeStatus.confirm;
      } else if (this.checkFailType(item)) {
        return StatusConstants.chargeStatus.fail;
      }
      return this.useTicketFlag ? (this.extractAmountFromArray(item, 'purchaseAmount')) : '¥' + formatLocalString(item.amount);
    },
    addSearch() {
      this.isSearchFormOpened = !this.isSearchFormOpened;
    },
    showCancelChargePopup() {
      if (!this.isCancelDisabled) {
        if (this.historyDetail?.usedPayment) {
          this.showModal('cancelPaymentModal');
        } else {
          this.showModal('cancelChargeModal');
        }
      }
    },
    async cancelPayment() {
      if (!this.isCancelDisabled) {
        this.$store.dispatch('common/setButtonLoading', true);
        const action = this.useTicketFlag ? 'getHistoryPaymentsTicket' : 'getHistoryPayments';
        await this.$store.dispatch(`history/${action}`, {
          subdomain: this.subdomain,
          chargeId: this.historyDetail?.id || '',
          manualChargeId: this.historyDetail?.manualChargeId || '',
        });
        this.closeModal('cancelPaymentModal');
        sessionStorage.setItem('noGetPaymentList', true);
        sessionStorage.setItem('csvData', JSON.stringify({
          chargeId: this.historyDetail?.id || '',
          manualChargeId: this.historyDetail?.manualChargeId || '',
        }))
        this.$router.push({ name: 'EventHistoryUse' });
      }
    },
    async cancelCharge() {
      if (!this.isCancelDisabled) {
        const result = await this.$store.dispatch('history/cancelHistoryCharge', {
          subdomain: this.subdomain,
          id: this.item.id,
        });
        if (result) {
          this.$store.dispatch('common/setButtonLoading', true);
          await this.$store.dispatch('history/getHistoryChargeDetail', {
            id: this.item.id,
            subdomain: this.subdomain,
          });
          this.closeModal('cancelChargeModal');
          this.$refs.pagination.getPaginationList();
          this.$message.showSuccess('cancelCharge');
        }
      }
    }
  },
  created(){
    // this.searchForm['type'] = 1
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('aggregate/getChildEventAggregate'),
      this.getTicketList(),
      this.$store.dispatch('history/getMethodList'),
    ]).finally(() => {
      this.$loading.clear(loading);
    });
  },
  watch: {
    useTicketFlag(value){
      if (value) {
        this.getTicketList();
      }
    }
  }
};
</script>
<style scoped>
  .table-child>td {
    padding: 0;
  }
  .table-child-added {
    padding: 16px;
    display: block;
  }
  .table-child table {
    width: 100%;
  }
  .table-child-title {
    padding: 16px;
    background: #f5f5fb;
    border-top: 1px solid #e1e2eb;
    border-bottom: 1px solid #e1e2eb;
    text-align: start;
    font-weight: bold;
  }
  .table-child table tr td {
    border-right: none !important;
  }
  .table-child table tr:last-child td {
    border-bottom: none !important;
  }
  .table-child table tr:last-child th {
    border-bottom: none !important;
  }
</style>
